<template>
  <div class="">
    <div class="header">
      <div class="title_1">支持运行库</div>
      <div class="title_2">如软件缺少运行库请从以下链接下载</div>
      <div class="downArea">
        <div class="downMask"></div>
        <div class="titleIcon"></div>
        <div class="windowTitle">Windows</div>
        <div class="line_1"></div>
        <div class="line_2"></div>
        <ul class="ulStyle">
          <li class="liStyle">
            <p class="iconPublic icon_1"></p>
            Vulkan（Latest Runtime/zip）
            <a
              href="https://vulkan.lunarg.com/sdk/home#windows"
              target="_blank"
              rel="noopener noreferrer"
              ><el-button type="success" size="mini">下载</el-button>
            </a>
          </li>
          <li class="liStyle">
            <p class="iconPublic icon_2"></p>
            VC++运行
            <a
              href="https://docs.microsoft.com/en-US/cpp/windows/latest-supported-vc-redist?view=msvc-170"
              target="_blank"
              rel="noopener noreferrer"
              ><el-button type="success" size="mini">查看</el-button></a
            >
          </li>
          <li class="liStyle">
            <p class="iconPublic icon_3"></p>
            英伟达驱动
            <a
              href="https://www.nvidia.cn/Download/Find.aspx?lang=cn"
              target="_blank"
              rel="noopener noreferrer"
              ><el-button type="success" size="mini">查看</el-button></a
            >
          </li>
          <li class="liStyle">
            <p class="iconPublic icon_4"></p>
            AMD驱动
            <a
              href="https://www.amd.com/zh-hant/support"
              target="_blank"
              rel="noopener noreferrer"
              ><el-button type="success" size="mini">查看</el-button></a
            >
          </li>
        </ul>
      </div>
      <div class="limit">最低支持WIN10系统</div>
    </div>
    <div class="supportFooter">
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>
import Footer from './Footer.vue'
export default {
  name: 'Support',
  data() {
    return {}
  },
  components: {
    'footer-component': Footer
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 900px;
  background-image: url('/img/support/banner.png');
  background-size: 100% 100%;
}
.title_1 {
  position: absolute;
  top: 202px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #ffffff;
}
.title_2 {
  position: absolute;
  top: 258px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #cecece;
}
.downArea {
  position: absolute;
  top: 318px;
  left: 50%;
  transform: translateX(-50%);
  width: 705px;
  height: 385px;
}
.downMask {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 705px;
  height: 385px;
  background-color: #151515;
  opacity: 0.6;
  border: 1px solid #ffffff;
  border-radius: 10px;
}
.titleIcon {
  position: absolute;
  top: 26px;
  left: 256px;
  width: 31px;
  height: 31px;
  background-image: url('/img/support/windows_w.png');
  background-size: contain;
}
.windowTitle {
  position: absolute;
  top: 22px;
  left: 307px;
  font-size: 30px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #fffdfd;
}
.line_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 705px;
  height: 80px;
  border-bottom: 2px solid #00000080;
}
.line_2 {
  position: absolute;
  top: 0;
  left: 213px;
  width: 274px;
  height: 80px;
  border-bottom: 2px solid #7fe96e;
  border-radius: 1px;
}
.ulStyle {
  position: absolute;
  top: 80px;
  left: 51px;
  width: 703px;
  height: 305px;
  /* background-color: rgba(157, 165, 44, 0.603); */
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 24px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #999999;
}
.liStyle {
  /* background-color: aqua; */
  width: 705px;
  height: 24px;
  margin-top: 40px;
  text-align: left;
  padding-left: 51px;
  /* background: url('/img/support/windows_B.png') no-repeat;
  background-size: 24px 100%; */
  line-height: 24px;
}
.iconPublic {
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 0 0 0 -50px;
}
.icon_1 {
  background: url('/img/support/vulkan.png') no-repeat;
  background-size: 24px 100%;
}
.icon_2 {
  background: url('/img/support/vc.png') no-repeat;
  background-size: 24px 100%;
}
.icon_3 {
  background: url('/img/support/nvidia.png') no-repeat;
  background-size: 24px 100%;
}
.icon_4 {
  background: url('/img/support/Amd.png') no-repeat;
  background-size: 24px 100%;
}

.el-button {
  position: absolute;
  right: 100px;
}
.limit {
  position: absolute;
  top: 730px;
  width: 100%;
  text-align: center;

  font-size: 24px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #ffffff;
}
a {
  text-decoration: none;
}
.supportFooter {
  position: absolute;
  top: 900px;
  left: 0;
  width: 1920px;
  height: 200px;
}
</style>
